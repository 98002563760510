import React from 'react'

import Layout from '../../components/layout'
import { colors, GreenBtn, md } from '../../common'

export default () => (
    <Layout>
      <div css={`padding:2rem;text-align:center;${md}{padding:1rem;}`}>
        <img css={`max-width:400px;width:100%;`} src={'https://s3.amazonaws.com/underwoods/_designers/marco-bicego/logo.png'} alt={`logo for Marco Bicego`} />
        <p css={`color:${colors.greyDarker};padding:2rem;line-height:24px;${md}{padding:1rem;}`}>
        Marco Bicego's fine Italian jewelry is exclusive to Underwood's in the Jacksonville market and can be found in all of our locations. All of Bicego's designs are hand-crafted in the Venetian region of Italy.
        </p>
        <div>
          <a href='http://shop.underwoodjewelers.com/marco-bicego' target='_blank'><GreenBtn>Shop Marco Bicego</GreenBtn></a>
        </div>
        <div>
          <img css={`max-width:800px;width:100%;padding-top:3em;`} src="https://s3.amazonaws.com/underwoods/_designers/marco-bicego/feat.jpg" alt="Marco Bicego featured illustration"/>
        </div>
      </div>
    </Layout>
)
